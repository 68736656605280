


window.Client = {
	init: function() {
	    
		document.querySelector("body").addEventListener("change", e => {
		    console.log(e)
		})
	},

	onRender: function(screenId) {
		// Do something when the flow renders
	}
}